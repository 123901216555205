import React from "react";
import { useState } from "react";
import Resume from "../Bruno_Godinho.pdf";

export default function Navbar() {
  const [hamburgerIsActive, setHamburgerIsActive] = useState(false);
  const htmlTag = document.querySelector("html");

  const toogleHamburger = () => {
    setHamburgerIsActive((prevState) => {
      return !prevState;
    });
    htmlTag.classList.toggle("hidden");
  };
  return (
    <div id="nav" className="container">
      <nav
        className="navbar"
        style={{ background: "transparent", zIndex: 100 }}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand ">
          <a href="/" className="navbar-item">
            <img src="favicon.ico" alt="" />
          </a>
          <button
            onClick={toogleHamburger}
            className={
              "navbar-burger has-text-white-bis " +
              (hamburgerIsActive ? "is-active" : "")
            }
            data-target="navMenu"
            aria-label="menu"
            aria-expanded="false"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>

        <div className="navbar-menu " style={{ paddingRight: "30px" }}>
          <div className="navbar-end">
            <a href="#about" className="navbar-item has-text-link-light">
              About
            </a>

            <a href="#experience" className="navbar-item has-text-link-light">
              Experience
            </a>

            <a href="#projects" className="navbar-item has-text-link-light">
              Projects
            </a>

            <a href="#contact" className="navbar-item has-text-link-light">
              Contact
            </a>
            <a
              href={Resume}
              rel="noreferrer"
              target="_blank"
              className="button is-primary resume-btn"
            >
              Resume
            </a>
          </div>
        </div>
      </nav>

      <aside
        className={
          "menu " + (hamburgerIsActive ? "menu-mobile" : "menu-mobile-hidden")
        }
        style={{ background: "#223d6b" }}
      >
        <ul className="menu-list" style={{ textAlign: "center" }}>
          <li>
            <a
              onClick={toogleHamburger}
              href="#about"
              className="navbar-item has-text-link-light"
            >
              About
            </a>
          </li>
          <li>
            <a
              onClick={toogleHamburger}
              className="navbar-item has-text-link-light"
              href="#experience"
            >
              Experience
            </a>
          </li>
          <li>
            <a
              href="#projects"
              onClick={toogleHamburger}
              className="navbar-item has-text-link-light"
            >
              Projects
            </a>
          </li>
          <li>
            <a
              href="#contact"
              onClick={toogleHamburger}
              className="navbar-item has-text-link-light"
            >
              Contact
            </a>
          </li>
          <li>
            <a
              href={Resume}
              rel="noreferrer"
              target="_blank"
              className="button is-primary resume-btn"
            >
              Resume{" "}
            </a>
          </li>
          <li></li>
        </ul>
      </aside>
    </div>
  );
}
