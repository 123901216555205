import React from "react";
import data from "../data.json";

export default function projects() {
	const openProject = (link) => {
		window.open(link, "_blank");
	};

	return (
		<div className="section" id="projects">
			<h1 className="has-text-primary pb-4">- My projects</h1>
			<div className="grid-container">
				{data.examples.map((project) => (
					<div
						className="grid-item h-auto"
						key={project.id}
						onClick={() => openProject(project.live_link)}
						target="_blank"
					>
						<div className="project-icons">
							<a href={project.live_link} rel="noreferrer" target="_blank">
								<i className="fas fa-external-link-square-alt is-size-3 has-text-primary"></i>
							</a>
							<a href={project.github_link} rel="noreferrer" target="_blank">
								<i className="fab fa-github is-size-3	has-text-primary	"></i>
							</a>
						</div>
						<div className="project-text">
							<h1 className="has-text-white-ter	is-size-5 pb-3">
								{project.project_name}
							</h1>
							<p className="has-text-grey">{project.description} </p>
						</div>
						<div>
						{project.technologies.map((tech) => (
							<span className="tag mr-3 mb-3 is-primary" key={tech}>
								{tech}
							</span>
						))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
