import React from "react";

export default function Footer() {
	return (
		<footer className=" section footer-sec">
			<div className=" has-text-centered">
				<p className="has-text-grey-light">Created by Bruno Godinho</p>
				<div className="footer-icons pt-3">
					<a
						href="https://www.linkedin.com/in/bruno-godinho-37262912/"
						target="_blank"
						rel="noreferrer"
					>
						<i className="fab fa-linkedin is-size-3 has-text-primary"></i>
					</a>
					<a
						href="https://github.com/caixarel"
						rel="noreferrer"
						target="_blank"
					>
						<i className="fab fa-github is-size-3	has-text-primary	"></i>
					</a>
				</div>
			</div>
		</footer>
	);
}
