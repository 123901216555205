import React from "react";

export default function Experience() {
	return (
		<div className="section" id="experience">
			<h1 className="has-text-primary	pb-4">
				- Job experience as Web develeper
			</h1>
			<div className="box">
				<p className="pb-4 has-text-white">
					<b>March 2022</b> Front-end developer Internship at{" "}
					<a className="link" href="www.nodalview.com">
						Nodalview
					</a>{" "}
					<br />
					Duration - 4 months
				</p>
				<p className="has-text-grey">
					During my internship I was responsible some bug fixing, implementation
					of new features, maintaining and refactoring of older components,
					developemt of new components. This internship was a exelelent
					oportunity to improve and learn new React comcepts, use of back-end
					API's , learn the basics of GRAPHQL and work closely with a great
					group of experienced developers.
				</p>
			</div>

			<div className="box">
				<p className="pb-4 has-text-white">
					<b>September 2022</b> Junior Front-end developer at{" "}
					<a className="link" href="www.bricsys.com">
						Bricsys
					</a>{" "}
					<br />
					Duration - Ongoing
				</p>
				<p className="has-text-grey">
					I'm part of the marketing team,some of my daily tasks are bug fixing,
					creation of new pages , maintain or refactor existing code, improve
					SEO . At Bricsys I've been learning about Vue and Nuxt.js .
				</p>
			</div>
		</div>
	);
}
