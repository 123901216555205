import "./App.css";
import "bulma/css/bulma.min.css";
import Projects from "./components/projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import About from "./components/About";
import Intro from "./components/Intro";
import Experience from "./components/Experience";
import GoUPButton from "./components/GoUPButton";

function App() {
	return (
		<div className="App is-relative" style={{ background: "#0b1930" }}>
			<Navbar />
			<div className="container ">
				<Intro />
				<About />

				<Experience />

				<Projects />
				<Contact />
				<Footer />
				<GoUPButton />
			</div>
		</div>

		//todo
		//back to top button
	);
}

export default App;
