import React from "react";

export default function About() {
	return (
		<div className="section " id="about">
			<div className="columns mt-6">
				<div className="column is-9">
					<h1 className="has-text-primary	pb-4">- About me </h1>
					<p>
						Hi there, My name is Bruno Godinho and I always loved to build
						things and work with computers. I've worked as a
						Non-destructive-Testing Inspector for 12 years until I finally
						decided to pursue a career where I could buils stuff using a
						computer.
						<br />
						<br />
						So in 2021 decided to learn code and since I live in belgium I
						joined the great Web Development Bootcamp{" "}
						<a className="link" href="www.lewagon.com">
							Le Wagon
						</a>
						.<br></br>
						<br></br>There I learned the basics of Full-stack Development, using
						<b>Ruby on rails</b> on the back-end and <b>HTML </b>, <b>CSS </b>
						and <b>Javascript </b>
						on the front-end. At the same time I was learning about <b>React</b>
						and <b>TailWind</b> on my own.
						<br />
						<br />
						I also created some small projects to practice everything I've been
						learning and I continue to learn new things by watching Development
						videos on youtube and also by doing online courses.
						<br />
						<br />
						On the personal side, I'm a hard-working person who loves a
						challenge , a problem so solve and I love to learn new things and
						get better .<br></br>
						<br></br>
						Like Albert Einstein once said “When you stop learning you start
						dying”
					</p>
				</div>
				<div className="column is-3 m-auto has-text-centered	">
					<img src="Bruno_G.jpg" alt="" className="profile-img" />
				</div>
			</div>
		</div>
	);
}
