import React from "react";

export default function Contact() {
	return (
		<div className="section" id="contact">
			<h1 className="has-text-primary	pb-4">- Contact me</h1>
			<p className="pb-6">
				I’m not currently looking for any new opportunities but anyways my inbox
				is always open. Whether you have a question or want to discuss something
				else feel free to contact me.
			</p>
			<a href="mailto:godinho_bruno@hotmail.com" className="button is-primary">
				Contact me
			</a>
		</div>
	);
}
