import React from "react";
import { useState } from "react";

export default function GoUPButton() {
	const [isVisible, setIsVisible] = useState(false);

	window.onscroll = function (ev) {
		if (window.scrollY > 650 && !isVisible) {
			setIsVisible(true);
		} else if (window.scrollY <= 650 && isVisible) {
			setIsVisible(false);
		}
	};

	return (
		<a
			href="#nav"
			className={
				"button is-primary go-up-link " + (isVisible ? "visible" : "invisible")
			}
		>
			<i className="fas fa-arrow-circle-up"></i>
		</a>
	);
}
