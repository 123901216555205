import React from "react";
import Resume from "../Bruno_Godinho.pdf";

export default function Intro() {
	return (
		<div className="section intro">
			<h1 className="has-text-primary mb-3">Hi, my name is</h1>
			<h1 className="has-text-link-light title-name">Bruno Godinho</h1>
			<h1 className=" has-text-grey-lighter title-role ">
				Front end Developer
			</h1>
			<p className="description ">
				I've been working as a Front end Web developer since 2022, currently for
				the company{" "}
				<a className="link" href="www.bricsys.com">
					Bricsys
				</a>{" "}
				where my role is to maintain and improve the current marketing website.
			</p>
			<a
				href={Resume}
				rel="noreferrer"
				target="_blank"
				className="button is-primary resume-btn mt-6"
			>
				Check my resume
			</a>
		</div>
	);
}
